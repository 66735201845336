.unit-pricelist-v1 {
	.show-more-btn {
		margin: 10px 0 10px 0;
		display: inline-block;
	}

	.pricelist {
		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}
	}
}